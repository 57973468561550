import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Partner from './pages/Partner';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import QRdoor from './pages/QRdoor';
import Redirect from './components/Redirect';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}/>
          <Route index element={<Home />} />
          <Route path="partner" element={<Partner />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="qrdoor" element={<QRdoor />} />
          <Route path="qrdoor/:id" element={<Redirect />} />
          <Route path="download" element={<Redirect />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
