import Services from "./Services";

function About(){
    return(
        <div className="container my-5 py-5 px-4">
            <div className="row g-5">
                <div class="col-md-6 d-flex">
                    <div>
                        <h1 style={{"font-weight": "bold","font-size": "40px"}}>Services that we offer</h1>
                        <p style={{"font-size": "16px"}}>We offer services accross 10+ categories and we have well trained and expirienced service professionals into our platform</p>
                        <div className="row g-4 mt-3">
                            <div className="col-md-6">
                                <div className="highlight-card">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                <img src="/assets/images/quick.png" className="img-fluid highlight-img" alt="..."/>
                                            </div>
                                            <div className="col-12">
                                                <h5 className="card-title">Quick</h5>
                                                <p className="card-text">Receive instant quotations by top professionals from your locality</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="highlight-card">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                <img src="/assets/images/saftey.webp" className="img-fluid highlight-img" alt="..."/>
                                            </div>
                                            <div className="col-12">
                                                <h5 className="card-title">Saftey first</h5>
                                                <p className="card-text">We monitor and provide all requirements for both customer and professionals saftey</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="highlight-card">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                <img src="/assets/images/support.webp" className="img-fluid highlight-img" alt="..."/>
                                            </div>
                                            <div className="col-12">
                                                <h5 className="card-title">24/7 support</h5>
                                                <p className="card-text">Stay connected through our customer support team throughout the order</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="highlight-card">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                <img src="/assets/images/verified.webp" className="img-fluid highlight-img" alt="..."/>
                                            </div>
                                            <div className="col-12">
                                                <h5 className="card-title">Verified</h5>
                                                <p className="card-text">Choose the right professionals through our verified profiles and badges</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <Services/>
                </div>
            </div>
        </div>
    )
}

export default About