import { useDebugValue, useEffect } from "react";

function Services(){
    useEffect(
        ()=>{
            setAnimations();
        },[]
    )
    const setAnimations = ()=>{
        const works = document.querySelectorAll(".work");
        var interval = 2;
        works.forEach((work)=>{
            work.style.animation="Waves "+interval+"s infinite";
            interval+=0.5;
            if(interval>4){
                interval=2;
            }
        })
    }
    return (
        <div>
            <div className="row">
                <div className="col-4">
                    <div className="row mt-3 g-4">
                        <div className="col-12">
                            <div class="work" onClick={()=>{window.open('https://app.homeradius.in');}} >
                                <div class="img d-flex align-items-end justify-content-center" style={{backgroundImage:`url(/assets/images/works/electrician.jpg)`}}>
                                    <div class="text w-100">
                                        <h2>Electricians</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div class="work" onClick={()=>{window.open('https://app.homeradius.in');}}>
                                <div class="img d-flex align-items-end justify-content-center" style={{backgroundImage:`url(/assets/images/works/carpenter.jpg)`}}>
                                    <div class="text w-100">
                                        <h2>Carpenters</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div class="work" onClick={()=>{window.open('https://app.homeradius.in');}}>
                                <div class="img d-flex align-items-end justify-content-center" style={{backgroundImage:`url(/assets/images/works/mechanic.jpg)`}}>
                                    <div class="text w-100">
                                        <h2>Vehicle mechanics</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="row mt-3 g-4">
                        <div className="col-12">
                            <div class="work" onClick={()=>{window.open('https://app.homeradius.in');}}>
                                <div class="img d-flex align-items-end justify-content-center" style={{backgroundImage:`url(/assets/images/works/painter.jpg)`}}>
                                    <div class="text w-100">
                                        <h2>Indoor and outdoor painters</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div class="work" onClick={()=>{window.open('https://app.homeradius.in');}}>
                                <div class="img d-flex align-items-end justify-content-center" style={{backgroundImage:`url(/assets/images/works/driver.jpeg)`}}>
                                    <div class="text w-100">
                                        <h2>Acting drivers</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div class="work" onClick={()=>{window.open('https://app.homeradius.in');}}>
                                <div class="img d-flex align-items-end justify-content-center" style={{backgroundImage:`url(/assets/images/works/plumber.jpg)`}}>
                                    <div class="text w-100">
                                        <h2>Plumbers</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="row mt-3 g-4">
                        <div className="col-12">
                            <div class="work" onClick={()=>{window.open('https://app.homeradius.in');}}>
                                <div class="img d-flex align-items-end justify-content-center" style={{backgroundImage:`url(/assets/images/works/eventplanners.webp)`}}>
                                    <div class="text w-100">
                                        <h2>Event planners</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div class="work" onClick={()=>{window.open('https://app.homeradius.in');}}>
                                <div class="img d-flex align-items-end justify-content-center" style={{backgroundImage:`url(/assets/images/works/housekeeping.jpg)`}}>
                                    <div class="text w-100">
                                        <h2>House keeping and cleaning</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div class="work" onClick={()=>{window.open('https://app.homeradius.in');}}>
                                <div class="img d-flex align-items-end justify-content-center" style={{backgroundImage:`url(/assets/images/works/rituals.jpg)`}}>
                                    <div class="text w-100">
                                        <h2>Religious rituals</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services