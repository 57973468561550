function ProcessFlow(){
    return(
        <div className="container my-5 py-5 px-4">
            <div className="row g-4">
                <div class="col-md-6">
                    <div>
                        <h1 style={{"font-weight": "bold","font-size": "40px"}}>How it works</h1>
                        <p style={{"font-size": "16px"}}>We follow simple and straight forward process as mentioned to get your order fullfilled without any flaws.</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row g-4 my-3">
                        <div class="col-1 number-holder">1</div>
                        <div class="col-10 d-flex align-items-center">
                            <div>
                                <h5>Click on book now</h5>
                                <p>Fill your contact details along with the service you want and then click submit</p>
                            </div>
                        </div>
                    </div>
                    <div class="row g-4 my-3">
                        <div class="col-1 number-holder">2</div>
                        <div class="col-10 d-flex align-items-center">
                            <div>
                                <h5>Get estimates</h5>
                                <p>Our dedicated executive will get in touch with you providing the estimations from different providers within 10 minutes</p>
                            </div>
                        </div>
                    </div>
                    <div class="row g-4 my-3">
                        <div class="col-1 number-holder">3</div>
                        <div class="col-10 d-flex align-items-center">
                            <div>
                                <h5>Choose a provider</h5>
                                <p>Select and confirm a provider and pay <b>just 20% of the fee</b> to book</p>
                            </div>
                        </div>
                    </div>
                    <div class="row g-4 my-3">
                        <div class="col-1 number-holder">4</div>
                        <div class="col-10 d-flex align-items-center">
                            <div>
                                <h5>It's done !</h5>
                                <p>The selected provider will reach your location on time and complete the service flawlessly, our dedicated executive will stay in touch until the service gets done</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProcessFlow