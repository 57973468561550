function Navbar(){
    return (
        <nav class="navbar navbar-expand-lg bg-brandbg sticky-top">
            <div class="container">
                <a class="navbar-brand" href="/">
                    <img src={'/assets/brand/logo-full.png'} alt="logo" height="23" class="d-inline-block align-text-top"/>
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
                <span class="navbar-text">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="/partner">Become a freelancer</a>
                        </li>
                        <li class="nav-item ms-4">
                            <a href="https://app.homeradius.in" className="btn btn-primary btn-lg px-5">Book Now</a>
                        </li>
                    </ul>
                </span>
                </div>
            </div>
        </nav>
    )
}

export default Navbar