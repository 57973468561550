function Faq(){
    return(
        <div className="container my-5 py-5 px-4">
            <div className="row g-4">
                <div class="col-md-6 d-flex">
                    <div>
                        <h1 style={{"font-weight": "bold","font-size": "40px"}}>FAQ's</h1>
                        <p style={{"font-size": "16px"}}>What people asked frequently</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="accordion white-box-container" id="accordionExample">
                        <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <b>How can I follow up my order ?</b>
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                You can track the entire timeline through our dedicated app. Also a dedicated support person will be alloted to assist you throughout the order. You can always access the live chat button in our website to get instant support.
                            </div>
                        </div>
                        </div>
                        <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <b>Should I pay the complete amount while booking ?</b>
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Initially 20% of the amount will be paid through online payment gateway (UPI, Netbanking, Credit and Debit cards and Wallets). Rest 80% can be paid directly to the service provider after the completion of the work.
                            </div>
                        </div>
                        </div>
                        <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <b>What will happen if I cancel the order after paying 20% advance ?</b>
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                With proper reasoning and validation, 20% amount will be refunded back to your account.
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faq