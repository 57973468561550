import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import { PopupButton } from '@typeform/embed-react'

function Partner(){
    return(
        <div>
            <Navbar/>
            <div className="container my-4 py-4">
                <div className="row d-flex align-items-center">
                    <div className="col-md-6">
                        <p className="hero-title mb-4">
                            Work anytime, anywhere with HomeRadius
                        </p>
                        <p className="hero-subtitle mb-4">
                            Join our fast growing freelance community and increase your earnings by 10 times. Just one click away from your dream opportunity.
                        </p>
                        <div className="hero-btns mb-3">
                            <PopupButton id="zs296Qbd" className="btn btn-primary">
                                Become a freelancer
                            </PopupButton>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-center">
                        <img src={'/assets/images/partner-hero.webp'} alt="hero" className="img-fluid"/>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
        
    )
}
export default Partner