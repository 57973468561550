import Navbar from "../components/Navbar"
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import Faq from "../components/Faq";
import ProcessFlow from "../components/ProcessFlow";
import Contact from "../components/Contact";
import Highlights from "../components/Highlights";
import About from "../components/About";

function Home(){
    return (
        <div>
            <Navbar/>
            <Hero/>
            <About/>
            <ProcessFlow/>
            <Faq/>
            <Contact/>
            <Footer/>
        </div>
    )
}

export default Home