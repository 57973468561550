import React from 'react'; 

const Contact = () => {
    return (
        <div className="container my-5 py-5 px-4">
            <div className="row g-4">
                <div className="col-md-6">
                    <h1 style={{"font-weight": "bold","font-size": "40px"}}>Get in touch</h1>
                    <p style={{"font-size": "16px"}}>We are here to help you out 24/7, fill out the form to get contacted or directly connect with us by: </p>
                    <p>Phone: <b>+91 63856 11080</b></p>
                    <p>Email: <b>info@homeradius.in</b> | <b>info@aviontive.com</b></p>

                </div>
                <div className="col-md-6">
                    <form className='white-box-container'>
                        <div className="row g-4 mb-3">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="name mb-4" className='form-label'>Name</label>
                                    <input type="text" className="form-control" id="name" placeholder="Enter your name" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="email" className='form-label'>Email</label>
                                    <input type="email" className="form-control" id="email" placeholder="Enter your email" />
                                </div>
                            </div>
                        </div>
                        <div className="row g-4 mb-3">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="phone" className='form-label'>Phone</label>
                                    <input type="text" className="form-control" id="phone" placeholder="Enter your phone" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="subject" className='form-label'>Subject</label>
                                    <input type="text" className="form-control" id="subject" placeholder="Enter your subject" />
                                </div>
                            </div>
                        </div>
                        <div className="row g-4 mb-3">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label for="message" className='form-label'>Message</label>
                                    <textarea className="form-control" id="message" rows="3"></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="row g-4 mb-3">
                            <div className="col-md-12">
                                <button type="submit" className="btn btn-secondary">Submit now</button>
                            </div>
                        </div>

                    </form>
                </div>

            </div>
        </div>
    );
}

export default Contact;
