import Herotext from "./Herotext"
import StoreBadge from 'react-store-badge';
import Highlights from "./Highlights"
import Services from "./Services"

function Hero(){
    return(
        <div className="container hero-container px-5">
            <div className="row g-5">
                <div className="col-md-6 d-flex align-items-center">
                    <div className='d-flex align-items-center flex-column mt-5'>
                        <div className="col-md-12 mb-5">
                            <p className="hero-title mb-3">
                                Your one stop solution for all home services
                            </p>
                            <p className="hero-subtitle mb-5">
                                Just a few taps away from hiring reliable freelance home service professionals such as Electricians, Plumbers, Carpenters, Painters, Acting drivers etc... with our app !
                            </p>
                            <div className="hero-btns mb-3">                    
                                <StoreBadge googlePlayUrl="https://play.google.com/store/apps/details?id=com.aviontive.homeradius"/>
                            </div>
                        </div>
                        <div className="col-md-12">
                            
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mt-0 d-flex align-items-center justify-content-center">
                    <div className="hero-scroll-container w-100">
                        <div className="hero-scroll-inner">
                            <img src="/assets/images/d1.png" className="img-fluid me-4" alt="..."/>
                            <img src="/assets/images/d2.png" className="img-fluid me-4" alt="..."/>
                            <img src="/assets/images/d3.png" className="img-fluid me-4" alt="..."/>
                            <img src="/assets/images/d4.png" className="img-fluid me-4" alt="..."/>
                            <img src="/assets/images/d5.png" className="img-fluid me-4" alt="..."/>
                        </div>
                        <div className="hero-scroll-inner">
                            <img src="/assets/images/d1.png" className="img-fluid me-4" alt="..."/>
                            <img src="/assets/images/d2.png" className="img-fluid me-4" alt="..."/>
                            <img src="/assets/images/d3.png" className="img-fluid me-4" alt="..."/>
                            <img src="/assets/images/d4.png" className="img-fluid me-4" alt="..."/>
                            <img src="/assets/images/d5.png" className="img-fluid me-4" alt="..."/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero