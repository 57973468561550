import ReactStoreBadges from 'react-store-badges'
import { FiSend } from 'react-icons/fi';

function Footer(){
    return(
        <section class="bottom">
            <footer class="w-100 py-4 flex-shrink-0 bg-hr-grey">
                <div class="container py-2">
                    <div class="row gy-4 gx-5">
                        <div class="col-lg-4 col-md-6">
                            <img src='/assets/brand/logo_full_dark.png' alt="logo" height="50" class="d-inline-block align-text-top mb-3"/>
                            <p class="small text-dim mb-4">HomeRadius is a product from Aviontive LLP. Now available as an Android app and web app, we are soon rolling out iOS app</p>
                            <ReactStoreBadges  platform={'android'} url={'https://play.google.com/store/apps/details?id=com.aviontive.homeradius'} locale={'en-us'} />
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <h5 class="text-white mb-3">Quick links</h5>
                            <ul class="list-unstyled text-muted">
                                <li><a href="/privacy">Privacy policy</a></li>
                                <li><a href="/terms">Terms & conditions</a></li>
                                <li><a href="/">Team behind</a></li>
                                <li><a href="/">Investors</a></li>
                            </ul>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <h5 class="text-white mb-3">Stay connected</h5>
                            <ul class="list-unstyled text-muted">
                                <li><a href="https://instagram.com/homeradius">Instagram</a></li>
                                <li><a href="https://twitter.com/homeradius">Twitter</a></li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <h5 class="text-white mb-3">Newsletter</h5>
                            <p class="small text-dim">Stay informed about latest product and company updates </p>
                            <form action="#">
                                <div class="input-group mb-3">
                                    <input class="form-control mx-2" type="text" placeholder="youremail@example.com"/>
                                    <button class="btn btn-secondary" id="button-addon2" type="button"><FiSend/></button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <p class="small mt-3 mb-0 w-100 text-center"> &copy;Copyrights. All rights reserved Aviontive LLP</p>
                </div>
            </footer>
        </section>
    )
}

export default Footer